.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
  }
  #components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }

  .ant-layout-footer {
    position: relative;
    width:100%;
    height: 150px;
    background-image: url('https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/houses2.svg') !important;
    background-repeat: repeat-x;
    background-position: center bottom;

    opacity: 0.4;
  }

  .ant-layout-content {
      min-height: calc(84vh - 140px) !important;
      padding-bottom: 30px !important;
      background-color: white;
      position: relative;
  }

  .background-img-footer{
    width:99%;
    height: 150px;
    background-image: url('https://d28f52sf2qukww.cloudfront.net/~_~static-assets/1983/img2/houses2.svg') !important;
    background-repeat: repeat-x;
    background-position: center bottom;
  }

  .ant-typography {
    font-family: Georgia, serif;
  }

  @media screen and (min-width: 100px) and (max-width: 799px) {
    .ant-layout-footer{
      height: 99%;
    }
}

