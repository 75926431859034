.card {
    padding-top: 40px;
    padding-bottom: 40px;
    border: 1px solid green;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
}

.card:hover {
    transform: translateY(-3px);
    transition: all 0.2s 0s ease-in-out;
    cursor: pointer;
    box-shadow: -4px -3px 10px 10px rgba(0,0,0,0.10);
}

.wide-input {
    width: 200px
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}