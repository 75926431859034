.card {
    padding-top: 40px;
    padding-bottom: 40px;
    border: 1px solid green;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    margin: 10px
}

.card:hover {
    transform: translateY(-3px);
    transition: all 0.2s 0s ease-in-out;
    cursor: pointer;
    box-shadow: -4px -3px 10px 10px rgba(0,0,0,0.10);
}

.wide-input {
    width: 200px
}

.ant-slider-dot{
    display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (min-width: 100px) and (max-width: 799px) {
    .ant-input{
        width: 300px !important;
    }
    .address-input{
        width: 300px;
    }
}

@media screen and (min-width: 800px) {
    .ant-input{
        width: 700px !important;
    }
    .address-input{
        width: 700px;
    }
}